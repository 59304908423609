import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import HeroImage from "../images/cms-hero-banner-img.png";
import DesignIcon from "../assets/icons/designer-icon.svg";
import DeveloperIcon from "../assets/icons/developers-icon.svg";
import MarketingIcon from "../assets/icons/marketing-icon.svg";
import EditIcon from "../assets/icons/edit-icon.svg";
import ModelIcon from "../assets/icons/model-icon.svg";
import LibraryIcon from "../assets/icons/library-icon.svg";
import MediaIcon from "../assets/icons/media-icon.svg";
import PersonalizeIcon from "../assets/icons/personalize-icon.svg";
import HeadlessCmsImage from "../images/headless-cms-image.jpg";
import LanguageIcon from "../assets/icons/language-square-icon.svg";
import ReuseComponentsIcon from "../assets/icons/main-component-icon.svg";
import CodecircleIcon from "../assets/icons/code-circle-icon.svg";
import VisualBuilderImage from "../images/visual-site-builder-image.jpg";
import MediaManagerImage from "../images/media-manager-image.jpg";
import PenToolIcon from "../assets/icons/pen-tool-icon.svg";
import ShapsIcon from "../assets/icons/shapes-icon.svg";
import MonitorIcon from "../assets/icons/monitor-icon.svg";
import ManagmentIcon from "../assets/icons/managment-icon.svg";
import GroupIcon from "../assets/icons/group-icon.svg";
import AbTestingIcon from "../assets/icons/ab-testing-icon.svg";
import ContentOpsImage from "../images/content-ops-image.jpg";
import ContentWebflowIcon from "../assets/icons/content-workflow-icon.svg";
import AccessControlIcon from "../assets/icons/access-control-icon.svg";
import CalenderIcon from "../assets/icons/calender-icon.svg";
import InsightIcon from "../assets/icons/insight-icon.svg";
import DeveloperSdkIcon from "../assets/icons/developer-sdk-icon.svg";
import MultiWorkspaceIcon from "../assets/icons/multi-workspace-icon.svg";
import CTABannerImage from "../images/journey-cta.png";
import IntegrationImage from "../images/integration-banner-image.png";
import CloudConnectionIcon from "../assets/icons/cloud-connection-icon.svg";
import BuildingIcon from "../assets/icons/buildings-icon.svg";
import SupportIcon from "../assets/icons/support-icon.svg";
import TranslateIcon from "../assets/icons/translate-icon.svg";
import CloudIcon from "../assets/icons/cloud-icon.svg";
import SecurityIcon from "../assets/icons/security-icon.svg";
import MerchandIcon from "../assets/icons/merchandise-icon.svg";
import JourneyIcon from "../assets/icons/journey-icon.svg";
import PenRulerIcon from "../assets/icons/pen-ruler-icon.svg";
import SEOIcon from "../assets/icons/seo-icon.svg";

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const CmsPage = () => {
    return (
        
        <div className="template-cms header-position">
            <Helmet>
                <title>Content Management System with Headless Commerce - Experro</title>
                <meta name="description" content="Use Experro's highly advance Headless CMS to manage your store and convert more vistors into customers."/>
            </Helmet>
            <Layout>
                <div className="hero-banner mb-208">
                    <div className="hero-banner-wrap">
                        <div className="left-block">
                            <div className="banner-text">
                                <h2 className="mb-24">
                                    Craft beautiful content experiences that inspire actions
                                </h2>
                                <p>Organize and create content with headless CMS, design landing pages, product pages, or entire sites with visual and no-code designer and unlock the power of content collaborate</p>
                                <Link className="button right-arrow" to="/contact/">Get a Demo</Link>
                            </div>
                        </div>
                        <div className="right-block">
                            <img loading="lazy" src={HeroImage} title="Craft beautiful content experiences that inspire actions" alt="Craft beautiful content experiences that inspire actions" />
                        </div>
                    </div>
                </div>

                <div className="team-works-section mb-208">
                    <div className="container">
                        <h3 className="font-medium line-height-60 mb-80">
                            Finally, the Content Management System <br /> that works for the entire eCommerce team.
                        </h3>
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-4">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <DesignIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Designers</h6>
                                            <p className="small-text">Design beautiful content, web pages, and experiences without depending on developers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <DeveloperIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Developers</h6>
                                            <p className="small-text">Build data models, content APIs, websites and storefronts with power tools and the latest tech stack.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MarketingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Marketers</h6>
                                            <p className="small-text">Automate content processes, deliver personalized experiences, and drive conversion.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <EditIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Editors</h6>
                                            <p className="small-text">Write, edit and publish content across multiple channels with the friendly CMS tools.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-with-image d-flex align-v-center mb-104" id="headless-cms">
                    <div className="left-block">
                        <div className="content-inner">
                            <h3 className="section-title">Headless CMS</h3>
                            <p className="mb-80">Create, collaborate and bring compelling content and stories to multiple channels simultaneously with more speed and fewer efforts</p>
                            <Accordion className="toggle-block" preExpanded={['a']}>
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><ModelIcon /></i> Powerful Content Modeling
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Model and store custom fields, types, and components as per your content and channel needs</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><LibraryIcon /></i> Intutive Content Editor
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Quickly Craft content with intuitive drag-and-drop editing features that make you efficient.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><MediaIcon /></i> Built-in Media Asset Manager
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Securely store and centralize all multimedia assets and deliver an ultra-fast experience with CDN.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><PersonalizeIcon /></i> Deliver Personalized Content
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Personalize all shopping experiences across all channels using built-in customer segmentation capabilities.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={HeadlessCmsImage} title="Headless CMS" alt="Headless CMS" />
                    </div>
                </div>

                <div className="icon-with-text-block border-bottom mb-104 pb-104">
                    <div className="container">
                        <div className="custom-row d-flex row-3">
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <LanguageIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Internationalization</h6>
                                        <p className="small-text">Speak to your customers in every language by localizing and globalizing content and products.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <ReuseComponentsIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Re-usable Components</h6>
                                        <p className="small-text">Design and build reusable and customizable components without needing the developer’s assistance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <CodecircleIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Content REST APIs</h6>
                                        <p className="small-text">Deliver entire page layouts to any frontend application through REST APIs and HTTPS.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="center-image-section" id="visual-site-builder">
                    <div className="container">
                        <h3 className="section-title text-center">Visual Site Builder</h3>
                        <p className="text-center mb-64">Build beautiful websites and storefronts in just a few minutes using a simple drag-and-drop site <br /> editor that supports preview and publishing for all devices</p>
                        <div className="image-block mb-104">
                            <img loading="lazy" src={VisualBuilderImage} title="Visual Site Bulder" alt="Visual Site Bulder" />
                        </div>
                    </div>
                </div>

                <div className="icon-with-text-block icon-with-text-flex-box mb-104">
                    <div className="container">
                        <div className="custom-row d-flex row-3">
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <PenToolIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Customized Designs</h6>
                                        <p className="small-text">We help you build custom themes and designs that match your website, storefront, and brand needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <ShapsIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Pre-built Library</h6>
                                        <p className="small-text">Use 50+ pre-built drag-and-drop ready components to speed-up site & content production without developer assistance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="item-inner">
                                    <div className="icon-block">
                                        <MonitorIcon />
                                    </div>
                                    <div className="text-block">
                                        <h6>Ready for all devices</h6>
                                        <p className="small-text">Create responsive content that renders swiftly across all screens or devices for the best user experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-with-image left-side-image d-flex align-v-center mb-104">
                    <div className="left-block">
                        <div className="content-inner">
                            <Accordion className="toggle-block" preExpanded={['a']}>
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><PenRulerIcon /></i> Build shoppable pages
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Model and store custom fields, types, and components as per your content and channel needs</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                        <i className="icon"><ModelIcon /></i> Use dynamic and headless content 
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Seamlessly integrate your headless CMS content into beautiful designs.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><PersonalizeIcon /></i> Personalize content for each customer
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Personalization capabilities that offer the right content, to the right audience, at the right time.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><SEOIcon /></i> Built-in Search Engine Optimization
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Improve visibility, searchability, and organic reach with built-in automated search engine optimization tools for each page of your website.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={MediaManagerImage} title="Media Manager Image" alt="Media Manager Image" />
                    </div>
                </div>

                <div className="flexible-content-block border-bottom mb-104 pb-104">
                    <div className="container">
                        <h3 className="mb-80 font-medium">Flexible and highly customizable for every use case</h3>
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <ManagmentIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Menu Management</h6>
                                            <p className="small-text">Enable customers to find the correct information and products by building intuitive and custom menus.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <GroupIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Custom Templates</h6>
                                            <p className="small-text">Use pre-defined, create custom templates, or upload an already created template to fit the business needs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <AbTestingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>A/B Testing</h6>
                                            <p className="small-text">Easily create and test hundreds of website versions to identify what works best for your visitors.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-with-image d-flex align-v-center mb-104" id="content-ops">
                    <div className="left-block">
                        <div className="content-inner">
                            <h3 className="section-title">Content Ops</h3>
                            <p className="mb-80">Run a reliable production environment with multiple stages that allow control and reduce the margin of error for publishers.</p>
                            <Accordion className="toggle-block" preExpanded={['a']}>
                                <AccordionItem className="toggle-item" uuid="a">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><ContentWebflowIcon /></i> Content Management Workflow
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Customized content creation and publishing workflows allow your team to manage projects</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="b">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><AccessControlIcon /></i> Fine-grain Access Control
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Create roles and permission to control accesses and actions of your content team.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="c">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><CalenderIcon /></i> Content Publishing & Scheduling
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Schedule publishing and unpublishing of pages or content to suit your marketing campaigns. </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem className="toggle-item" uuid="d">
                                    <AccordionItemHeading className="toggle-title">
                                        <AccordionItemButton>
                                            <i className="icon"><InsightIcon /></i> Content Performance Insights
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="toggle-content">
                                        <p>Evaluate your content performance to improve its reach to the target audience.</p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                    <div className="right-block">
                        <img loading="lazy" src={ContentOpsImage} title="Content Ops" alt="Content Ops" />
                    </div>
                </div>

                <div className="mb-208">
                    <div className="container">
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <DeveloperSdkIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Developer SDKs</h6>
                                            <p className="small-text">Use the out-of-the-box command-line tools, APIs, and libraries for building custom features, integrations, and customizations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <DeveloperIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Built-in Environments</h6>
                                            <p className="small-text">Use multiple environments for developing, testing, and publishing thousands of content simultaneously.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MultiWorkspaceIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Multiple Workspaces</h6>
                                            <p className="small-text">Organize your content initiatives, sites, or channels in multiple workspaces with their different designs, accesses, and content models.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="cta-banner mb-208">
                    <div className="container">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-end">
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CTABannerImage} title="CTA Banner Image" alt="CTA Banner Image" />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-block pb-64 pt-64">
                                        <h4>See how Experro can empower your business to transform eCommerce.</h4>
                                        <p className="mb-40">We're helping B2B, B2C, D2C, and retailers to reinvent their digital experiences for a customer-first future.</p>
                                        <Link className="button black-button" to="/contact/">Get a Demo</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="integrates-section text-center mb-104">

                    <div className="container">
                        <div className="title-block mb-32">
                            <h3 className="mb-32">Integrates with eCommerce  platforms <br /> and your existing apps</h3>
                            <Link className="btn-link right-arrow" to="/integration/">See Integrations</Link>
                        </div>
                        <div className="image-block">
                            <img loading="lazy" src={IntegrationImage} title="Integration Banner" alt="Integration Banner" />
                        </div>
                    </div>
                </div>
                
                <div className="scale-and-optimized-section pb-104 pt-104 yello-bg-color mb-208">
                    <div className="container">
                        <h3 className="section-title mb-32">
                            Built to scale and <br /> optimized to perform
                        </h3>
                        <p className="mb-80">Designed with the most advanced technologies and architecture, Experro offers a blazing-fast <br /> experience for billions of web and mobile users.</p>
                        <div className="icon-with-text-block">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid mb-60">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CloudConnectionIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Built for Massive Traffic</h6>
                                            <p className="small-text">Optimized and simplified to manage millions of engagements and billions of experiences for multiple websites.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-60">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <BuildingIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Enterprise Ready</h6>
                                            <p className="small-text">The unified experience platform is built to offer your business with industry-leading security, scalability, and flexibility.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid mb-60">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <SupportIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Multi-site Support</h6>
                                            <p className="small-text">Create and manage your organization's content websites in a practical and sustainable way.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <TranslateIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Multi-lingual</h6>
                                            <p className="small-text">Create and launch content for customers from any corner of the world in the language they understand. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <CloudIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Cloud Hosting</h6>
                                            <p className="small-text">Use our hosted version or create a dedicated deployment in your Azure, AWS, or GCP cloud environment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <SecurityIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Bullet-proof Security</h6>
                                            <p className="small-text">Secure multi-datacenter architecture that follows industry best practices and data encryption protocols at rest and in transit to prevent unauthorized access and leakage of data.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="experro-more-details-section mb-208">
                    <div className="container">
                        <h3 className="mb-32 text-center font-medium">There is more to Experro</h3>
                        <div className="icon-with-text-block icon-with-text-border-box">
                            <div className="custom-row d-flex flex-wrap row-3">
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <MerchandIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Merchandising</h6>
                                            <p className="small-text">Display and arrange products in a way that makes sense for your eCommerce customers.</p>
                                            <Link className="btn-link right-arrow" to="/discovery/#visual-merchandising">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <PersonalizeIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Personalization</h6>
                                            <p className="small-text">Get the right product in front of the right shopper with AI-powered Recommendations.</p>
                                            <Link className="btn-link right-arrow" to="/discovery/#personalized-recommendations">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner">
                                        <div className="icon-block">
                                            <JourneyIcon />
                                        </div>
                                        <div className="text-block">
                                            <h6>Marketing Automation</h6>
                                            <p className="small-text">Accelerate sales and marketing processes by automating omnichannel engagement.</p>
                                            <Link className="btn-link right-arrow" to="/journey/#marketing-automation">Learn more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
        
    );
}

export default CmsPage;